<template>
  <div
    eagle-list="list-data-action"
    class="d-flex gap-1"
    :class="dataActionClass"
  >
    <component
      v-for="(actionConfig, actionName) in listDataAction"
      :key="actionName"
      :list-key="listKey"
      :is="getComponent(actionName, actionConfig)"
      :name="actionName"
      :config="actionConfig"
      :row="row"
    ></component>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
    row: Object,
  },
  methods: {
    getComponent(name, config) {
      if(name == 'delete') return 'actionDelete'
      if(config.component) return config.component
      return this.$options.components[`actionBase`]
    },
  },
  computed: {
    dataActionClass() {
      if(this.listConfig.dataActionFlexColumnInTableMode != true) return null
      if(this.displayMode != 'table') return null
      return 'd-flex flex-column'
    },
    displayMode() {
      return this.$store.getters[`list/${this.listKey}/displayMode`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    listDataAction() {
      return this.$store.getters[`list/${this.listKey}/dataAction`]
    },
  },
  components: {
    actionBase: () => import('@/components/list/listDataAction/base.vue'),
    actionDelete: () => import('@/components/list/listDataAction/delete.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.gap-1
  gap: 1rem
</style>
